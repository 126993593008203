export default class EditableTable {
    constructor(table = null) {
        // Make sure there's an editable table element defined
        if (!table) return;

        this.table = table;

        this.addNewRowButton = this.table.nextElementSibling;
        this.tableBody = this.table.querySelector('tbody');

        this.controller = new AbortController();

        // Add event listeners
        this.table.addEventListener('click', (ev) => {
            if (ev.target.matches('[data-delete-table-row],[data-delete-table-row] *')) {
                this.deleteRow(ev);
            }
        }, { signal: this.controller.signal });

        if (this.addNewRowButton) {
            this.addNewRowButton.addEventListener('click', this.addRow);
        }
    }

    // Variables
    settings;

    table;

    tableBody;
    addNewRowButton;

    controller;

    // Methods
    addRow(ev) {
        let newRowHTML = ev.target.closest('button').dataset.addNewTableRow;
        let newIndex = ev.target.closest('button').dataset.rowIndex;
        ev.target.dataset.rowIndex = (parseInt(newIndex) + 1).toString();
        let table = ev.target.closest('div').querySelector('[data-editable-table]');
        let tableBody = table.querySelector('tbody');

        newRowHTML = newRowHTML.replace(/ROWCOUNT/g, newIndex);

        if (newRowHTML) {
            let range = document.createRange();
            range.selectNode(tableBody);
            let newRow = range.createContextualFragment(newRowHTML);

            tableBody.appendChild(newRow.querySelector('tr'));

            tableBody.querySelector('tr:last-child textarea').focus();

            let event = new Event(
                'oscar.updateSortableLists',
                {
                    bubbles: true,
                    cancelable: true
                }
            );

            table.dispatchEvent(event);
        }
    };

    deleteRow(ev) {
        let confirmDeleteRow = confirm('Are you sure you want to delete this row? (You cannot undo this action.)');

        if (confirmDeleteRow) {
            let row = ev.target.closest('tr');
            let table = row.closest('[data-editable-table]');
            row.parentNode.removeChild(row);

            let event = new Event(
                'oscar.updateSortableLists',
                {
                    bubbles: true,
                    cancelable: true
                }
            );

            table.dispatchEvent(event);
        }
    }

    /**
     * Destroy the current initialization
     */
    destroy() {
        // Make sure plugin has been initialized
        if (!this.table) return;

        // Remove all event listeners
        this.addNewRowButton.removeEventListener('click', this.addRow);
        this.controller.abort();
        // let rowDeleteButtons = this.table.querySelectorAll('[data-delete-table-row]');
        // if (rowDeleteButtons) {
        //     Array.prototype.forEach.call(rowDeleteButtons, function(item) {
        //         item.removeEventListener('click', this.deleteRow);
        //     }, this);
        // }

        // Reset variables
        this.table = null;
        this.tableBody = null;
        this.addNewRowButton = null;
    }
}

